import { Avatar, AvatarSkeleton } from "@clipboard-health/ui-components";
import type { AvatarSize } from "@clipboard-health/ui-theme";
import { isDefined } from "@clipboard-health/util-ts";
import { useWorkerProfilePicture } from "@src/appV2/Agents/api/useWorkerProfilePicture";

interface WorkerAvatarProps {
  workerId?: string;
  size?: AvatarSize;
}

export function WorkerAvatar(props: WorkerAvatarProps) {
  const { workerId, size } = props;
  const { data: profilePicture, isLoading: isProfilePictureLoading } = useWorkerProfilePicture(
    workerId ?? "",
    {
      enabled: isDefined(workerId),
    }
  );

  if (isProfilePictureLoading && isDefined(workerId)) {
    return <AvatarSkeleton size={size} />;
  }

  return <Avatar src={profilePicture?.distribution_url} size={size} />;
}
