import { type LocationState } from "history";
import {
  type AnchorHTMLAttributes,
  type DetailedHTMLProps,
  type ForwardedRef,
  forwardRef,
} from "react";
import { useHistory } from "react-router-dom";

function isInternalLink(href: string) {
  try {
    // eslint-disable-next-line no-new
    new URL(href);
    // valid URL means it's external
    return false;
  } catch {
    return true;
  }
}

interface ButtonInternalLinkProps
  extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  locationState?: LocationState;
}
/**
 * A wrapper around an anchor tag that handles both internal and external links.
 * For internal links, it uses react-router redirects to handle client-side navigation.
 * For external links, it behaves like a regular anchor tag.
 * Used as a `LinkComponent` prop for buttons to provide unified link handling.
 */
function BaseButtonInternalLink(
  props: ButtonInternalLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  const { href, onClick, children, locationState, ...restProps } = props;

  const history = useHistory();

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      {...restProps}
      ref={ref}
      href={href}
      // It is safe to always use _blank as internal links are handled by react-router
      target="_blank"
      onClick={(event) => {
        if (href && isInternalLink(href)) {
          event.preventDefault();
          history.push(href, locationState);
        }

        onClick?.(event);
      }}
    >
      {children}
    </a>
  );
}

export const ButtonInternalLink = forwardRef(BaseButtonInternalLink);
