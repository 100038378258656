import { mergeSxProps } from "@clipboard-health/ui-react";
import { type PaperVariant } from "@clipboard-health/ui-theme";
import { Paper, type SxProps } from "@mui/material";
import { type Theme } from "@mui/material/styles";
import { type ReactNode } from "react";

import { useRedesignStatusBarAppearance } from "../utils/useRedesignStatusBarAppearance";

interface Props {
  children: ReactNode;
  variant?: PaperVariant;
  sx?: SxProps<Theme>;
}

/**
 * This is minimalistic wrapper for a page. It has simple column flex layout as this is what we usually need
 * It's preferred to use components composition, so this component does not enforce any particular header, footer or margins
 */
export function PageWrapper(props: Props) {
  const { children, variant = "primary", sx } = props;

  // TODO: Remove this once we migrate to new shift discovery theme
  useRedesignStatusBarAppearance();

  return (
    <Paper
      variant={variant}
      sx={mergeSxProps(
        {
          height: "100vh",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        },
        sx
      )}
    >
      {children}
    </Paper>
  );
}
