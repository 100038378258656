import {
  DialogFooter as DialogFooterBase,
  type DialogFooterProps,
} from "@clipboard-health/ui-components";

import { ButtonInternalLink } from "./ButtonInternalLink";

export function DialogFooter(props: Omit<DialogFooterProps, "LinkComponent">) {
  return <DialogFooterBase {...props} LinkComponent={ButtonInternalLink} />;
}
