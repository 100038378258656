import {
  Button as ButtonBase,
  type ButtonProps as ButtonPropsBase,
} from "@clipboard-health/ui-components";
import { type LocationState } from "history";

import { ButtonInternalLink } from "./ButtonInternalLink";

interface ButtonProps extends Omit<ButtonPropsBase, "LinkComponent"> {
  locationState?: LocationState;
}

export function Button(props: ButtonProps) {
  return <ButtonBase {...props} LinkComponent={ButtonInternalLink} />;
}
