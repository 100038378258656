import {
  IconButton as IconButtonBase,
  type IconButtonProps,
} from "@clipboard-health/ui-components";

import { ButtonInternalLink } from "./ButtonInternalLink";

export function IconButton(props: Omit<IconButtonProps, "LinkComponent">) {
  return <IconButtonBase {...props} LinkComponent={ButtonInternalLink} />;
}
