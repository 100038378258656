import {
  SelectableBox as SelectableBoxBase,
  type SelectableBoxProps,
} from "@clipboard-health/ui-components";

import { ButtonInternalLink } from "./ButtonInternalLink";

export function SelectableBox(props: Omit<SelectableBoxProps, "LinkComponent">) {
  return <SelectableBoxBase {...props} LinkComponent={ButtonInternalLink} />;
}
