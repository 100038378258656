import {
  PickerButton as PickerButtonBase,
  type PickerButtonProps,
} from "@clipboard-health/ui-components";

import { ButtonInternalLink } from "./ButtonInternalLink";

export function PickerButton(props: Omit<PickerButtonProps, "LinkComponent">) {
  return <PickerButtonBase {...props} LinkComponent={ButtonInternalLink} />;
}
