import {
  Avatar,
  AvatarSkeleton,
  CbhIcon,
  iconSizeByAvatarSize,
} from "@clipboard-health/ui-components";
import { type AvatarSize, type IconButtonSize } from "@clipboard-health/ui-theme";
import { isDefined } from "@clipboard-health/util-ts";
import { Badge } from "@mui/material";
import { useFacilityPhotos } from "@src/appV2/Facilities/api/useFacilityPhotos";

interface FacilityAvatarProps {
  facilityId?: string;
  isFavorite?: boolean;
  size?: AvatarSize;
}

const badgeSizeByAvatarSize: Record<AvatarSize, IconButtonSize> = {
  small: "xSmall",
  medium: "small",
  large: "small",
} as const;

export function FacilityAvatar(props: FacilityAvatarProps) {
  const { facilityId, isFavorite, size = "medium" } = props;

  const { data: facilityPhotos, isInitialLoading: isFacilityPhotosLoading } = useFacilityPhotos(
    {
      facilityId: facilityId ?? "",
    },
    {
      enabled: isDefined(facilityId) && facilityId !== "",
    }
  );

  if (isFacilityPhotosLoading) {
    return <AvatarSkeleton size={size} />;
  }

  const photoUrl = facilityPhotos?.photosList[0]?.url;

  if (isFavorite) {
    const badgeSize = badgeSizeByAvatarSize[size];
    return (
      <Badge
        badgeContent={<CbhIcon type="like-filled" size={badgeSize} />}
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={(theme) => ({
          "& .MuiBadge-badge": {
            padding: 0,
            width: theme.size?.iconButton[badgeSize],
            height: theme.size?.iconButton[badgeSize],
            boxShadow: theme.shadows[2],
            backgroundColor: theme.palette.background.tertiary,
            borderColor: theme.palette.border?.subtle,
            borderWidth: theme.borderWidth?.thin,
            borderStyle: "solid",
            borderRadius: "50%",
          },
        })}
      >
        <Avatar src={photoUrl} size={size}>
          <CbhIcon type="building" size={iconSizeByAvatarSize[size]} />
        </Avatar>
      </Badge>
    );
  }

  return (
    <Avatar src={photoUrl} size={size}>
      <CbhIcon type="building" size={iconSizeByAvatarSize[size]} />
    </Avatar>
  );
}
