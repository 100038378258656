import {
  LoadingButton as LoadingButtonBase,
  type LoadingButtonProps as LoadingButtonPropsBase,
} from "@clipboard-health/ui-components";
import { type LocationState } from "history";

import { ButtonInternalLink } from "./ButtonInternalLink";

interface LoadingButtonProps extends Omit<LoadingButtonPropsBase, "LinkComponent"> {
  locationState?: LocationState;
}

export function LoadingButton(props: LoadingButtonProps) {
  return <LoadingButtonBase {...props} LinkComponent={ButtonInternalLink} />;
}
