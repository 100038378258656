import { type QueryClient } from "@tanstack/react-query";

import { invalidateMissingRequirements } from "../Accounts/Documents/api/useGetMissingRequirements";
import { invalidateOpenShiftCount } from "../OpenShifts/api/useOpenShiftCount";
import { invalidateOpenShifts } from "../OpenShifts/api/useOpenShifts";
import { invalidateAgentShifts } from "../OpenShifts/api/useWorkerShifts";
import { invalidateBookabilityStatus } from "../OpenShifts/ShiftAction/api/useBookabilityStatus";
import { invalidateWorkerPendingShiftInvites } from "../Shifts/ShiftInvites/api/useWorkerPendingShiftInvites";
import { invalidateWorkerRequirements } from "./Documents/api/invalidateWorkerRequirements";
import { invalidateGetOpenShifts } from "./Shift/Open/useGetOpenShifts";

export async function invalidateShifts(queryClient: QueryClient): Promise<void> {
  await Promise.all([
    invalidateGetOpenShifts(queryClient),
    invalidateAgentShifts(queryClient),
    invalidateOpenShiftCount(queryClient),
    invalidateOpenShifts(queryClient),
    invalidateWorkerPendingShiftInvites(queryClient),
    invalidateMissingRequirements(queryClient),
    invalidateWorkerRequirements(queryClient),
    invalidateBookabilityStatus(queryClient),
  ]);
}
