import { Switch, type SwitchProps, type SxProps, type Theme } from "@mui/material";

export function IosSwitch({
  size,
  ...props
}: Omit<SwitchProps, "sx" | "size"> & { size?: "small" | "medium" | "large" }) {
  const scale = (() => {
    switch (size) {
      case "small": {
        return 0.8;
      }

      case "medium": {
        return 1;
      }

      case "large": {
        return 1.25;
      }

      default: {
        return 1;
      }
    }
  })();

  const sx: SxProps<Theme> = (theme: Theme) => ({
    width: 42 * scale,
    height: 26 * scale,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: `${2 * scale}px`,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: `translateX(${16 * scale}px)`,
        color: theme.palette.common.white,
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22 * scale,
      height: 22 * scale,
    },
    "& .MuiSwitch-track": {
      borderRadius: `${(26 * scale) / 2}px`,
      backgroundColor: theme.palette.grey[300],
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  });

  return <Switch sx={sx} {...props} />;
}
