import {
  FloatingGoToTopButton as FloatingGoToTopButtonBase,
  type GoToTopButtonProps,
} from "@clipboard-health/ui-components";

import { ButtonInternalLink } from "./ButtonInternalLink";

export function FloatingGoToTopButton(props: Omit<GoToTopButtonProps, "LinkComponent">) {
  return <FloatingGoToTopButtonBase {...props} LinkComponent={ButtonInternalLink} />;
}
