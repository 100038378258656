import {
  StickyScrollPageHeader as StickyScrollPageHeaderBase,
  type StickyScrollPageHeaderProps,
} from "@clipboard-health/ui-components";
import { isDefined } from "@clipboard-health/util-ts";
import { RootPaths } from "@src/appV2/App/paths";
import { useHistory, useLocation } from "react-router-dom";

import { ButtonInternalLink } from "./ButtonInternalLink";

export type { StickyScrollPageHeaderProps } from "@clipboard-health/ui-components";

interface StickyScrollPageHeaderLocalProps
  extends Omit<StickyScrollPageHeaderProps, "navigateBack" | "LinkComponent"> {
  fallbackGoBackPath?: string;
}

export function StickyScrollPageHeader(props: StickyScrollPageHeaderLocalProps) {
  const { fallbackGoBackPath = RootPaths.HOME } = props;

  const history = useHistory();
  const location = useLocation();

  return (
    <StickyScrollPageHeaderBase
      {...props}
      navigateBack={() => {
        // no key means we can't go back
        if (isDefined(location.key)) {
          history.goBack();
        } else {
          history.push(fallbackGoBackPath);
        }
      }}
      LinkComponent={ButtonInternalLink}
    />
  );
}
