import {
  TextButton as TextButtonBase,
  type TextButtonProps,
} from "@clipboard-health/ui-components";

import { ButtonInternalLink } from "./ButtonInternalLink";

export { TEXT_BUTTON_SIZES } from "@clipboard-health/ui-components";

export function TextButton(props: Omit<TextButtonProps, "LinkComponent">) {
  return <TextButtonBase {...props} LinkComponent={ButtonInternalLink} />;
}
